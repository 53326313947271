// prefer default export if available
const preferDefault = m => m && m.default || m

exports.components = {
  "component---src-pages-404-js": () => import("/opt/build/repo/src/pages/404.js" /* webpackChunkName: "component---src-pages-404-js" */),
  "component---src-pages-agendar-cita-js": () => import("/opt/build/repo/src/pages/agendar-cita.js" /* webpackChunkName: "component---src-pages-agendar-cita-js" */),
  "component---src-pages-como-funciona-js": () => import("/opt/build/repo/src/pages/como-funciona.js" /* webpackChunkName: "component---src-pages-como-funciona-js" */),
  "component---src-pages-es-apropiado-js": () => import("/opt/build/repo/src/pages/es-apropiado.js" /* webpackChunkName: "component---src-pages-es-apropiado-js" */),
  "component---src-pages-index-js": () => import("/opt/build/repo/src/pages/index.js" /* webpackChunkName: "component---src-pages-index-js" */),
  "component---src-pages-page-2-js": () => import("/opt/build/repo/src/pages/page-2.js" /* webpackChunkName: "component---src-pages-page-2-js" */),
  "component---src-pages-que-es-js": () => import("/opt/build/repo/src/pages/que-es.js" /* webpackChunkName: "component---src-pages-que-es-js" */),
  "component---src-pages-success-js": () => import("/opt/build/repo/src/pages/success.js" /* webpackChunkName: "component---src-pages-success-js" */)
}

exports.data = () => import(/* webpackChunkName: "pages-manifest" */ "/opt/build/repo/.cache/data.json")

